import React, { useState, useEffect, useContext, createContext } from "react"

export const AppContext = React.createContext([{}, () => {}])

const initialState = {
  cart: null,
  lang: "pl",
  shippingID: "",
  showFlyCart: false,
  pageName: "",
  orderData: {},
  WPML: [],
  addToCartError: "",
  hasSubscriptionProduct: false,
  setPageName: () => {},
  setCart: () => {},
  setLang: () => {},
  toogleShowFlyCart: () => {},
  setOrderData: () => {},
  setWPML: () => {},
  setAddToCartError: () => {},
  toogleHasSubscriptionProduct: () => {},
}
const ThemeContext = createContext(initialState)

export const AppProvider = props => {
  const [cart, setCart] = useState(null)
  const [lang, setLang] = useState("pl")
  const [pageName, setPageName] = useState("page")
  const [shippingID, setShippingID] = useState("")
  const [showFlyCart, changeShowFlyCart] = useState(false)
  const [orderData, setOrderData] = useState({})
  const [WPML, setWPML] = useState({})
  const [addToCartError, setAddToCartError] = useState("")
  const [addToCartErrorIdProduct, setAddToCartErrorIdProduct] = useState("")
  const [hasSubscriptionProduct, toogleHasSubscriptionProduct] = useState({})

  const toogleShowFlyCart = data => {
    if (pageName === "cart" || pageName === "checkout") {
      return
    }
    if (typeof window !== "undefined") {
      const htmlElement = document.querySelector("html")
      if (data) {
        htmlElement.classList.add("widgetShow")
      } else {
        htmlElement.classList.remove("widgetShow")
      }
    }
    changeShowFlyCart(data)
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      let cartData = localStorage.getItem("woo-next-cart")
      cartData = null !== cartData ? JSON.parse(cartData) : ""
      setCart(cartData)

      let orderData = localStorage.getItem("woo-order")
      orderData = null !== orderData ? JSON.parse(orderData) : {}
      setOrderData(orderData)
    }
  }, [])

  return (
    <AppContext.Provider
      value={{
        cart,
        setCart,
        showFlyCart,
        toogleShowFlyCart,
        lang,
        setLang,
        shippingID,
        setShippingID,
        pageName,
        setPageName,
        orderData,
        setOrderData,
        WPML,
        setWPML,
        addToCartError,
        setAddToCartError,
        addToCartErrorIdProduct,
        setAddToCartErrorIdProduct,
        hasSubscriptionProduct,
        toogleHasSubscriptionProduct,
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}

const useTheme = () => {
  const context = useContext(ThemeContext)

  if (context === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider")
  }
  return context
}
export default useTheme
